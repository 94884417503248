body {
  @include body-font;
  //padding-top: 70px;
}

.page-wrapper {
  display: block;
  overflow: hidden;
  width: 100%;
}

main {
  float: left;
  min-height: 90vh;
  padding: 0px;
  width: 100%;
  @include media-breakpoint-down(md) {
    min-height: 350px;
  }
}

section {
  float: left;
  padding: 70px 0px;
  width: 100%;
}
img {
  display: inline-block;
  max-width: 100%;
}
  
.divImg {
    @include bg-cmn;
}
/*** Heading ***/
h1,
.h1 {
  @include font-xl;
}
h2,
.h2 {
  @include font-lg;
}
h3,
.h3 {
  @include font-md;
}
h4,
.h4 {
  @include font-sm;
}
h5,
.h5 {
  @include font-xs;
}
h6,
.h6 {
  @include font-xxs;
}
/*** Listing ***/
ul {
  margin: 0px 0px 30px;
  padding: 0;
  li {
    list-style: none;
    @include body-font;
    margin: 0px 0px 20px;
    padding: 0px 0px 0px 28px;
    position: relative;
    &:before {
      content: "";
      width: 6px;
      height: 6px;
      position: absolute;
      left: 0px;
      top: 11px;
      background: $secondary-color;
      @include border-radius(100%);
    }
  }
}
ul.list-unstyled {
  li {
    margin: 0px;
    padding: 0px;
    &:before {
      content: normal;
    }
  }
}
ol {
  margin: 0px 0px 30px;
  padding: 0px;
  list-style: none;
  li {
    counter-increment: av-counter;
    @include body-font;
    margin: 0px 0px 20px;
    padding: 0px 0px 0px 28px;
    position: relative;
    &:before {
      content: counter(av-counter) ".";
      counter-increment: none;
      color: $primary-color;
      position: absolute;
      left: 0px;
      top: 0px;
    }
  }
}
.was-validated{
  .form-control:valid{
    border-color:$primary-color;
    padding-right:0.75rem;
    background-image:none;
  }
}
.captcha-input-group{
  .form-control{
    border-top-right-radius: $input-border-radius !important;
    border-bottom-right-radius: $input-border-radius !important;
  }
}