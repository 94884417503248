// Import Fonts:
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900');
// Bootstrap variable override:

@font-face {
  font-family: 'Modern DOS 8x8';
  src: url('../fonts/ModernDOS8x8.woff2') format('woff2'),
      url('../fonts/ModernDOS8x8.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@import "bootstrap";
@import "variables";
// Project's custom variables or mixin:
@import "functions";
@import "mixins";
@import "custom";
// plugins:
// layouts:
// core
@import "core/project-sitemap";
@import "layout";
@import "sections/header";
@import "sections/footer";
// pages:
@import "pages/home";
@import "pages/about";
@import "pages/single-article";
// sections:
@import "sections/widget";
// Override anything at last:
@import "overrides";