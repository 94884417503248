@mixin linear-gradient($direction, $color-stops...) {
  // Direction has been omitted and happens to be a color-stop
  @if is-direction($direction) == false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

@mixin flex{display:-webkit-box;display:-ms-flexbox;display:flex;}
@mixin flex-wrap{-ms-flex-wrap:wrap;flex-wrap:wrap;}
@mixin flex-no-wrap{-ms-flex-wrap:nowrap;flex-wrap:nowrap;}
@mixin flex-align-center{-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
@mixin flex-align-start{-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;}
@mixin flex-align-end{-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;}
@mixin flex-justify-space-between{-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;}
@mixin flex-justify-center{-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;}
@mixin flex-justify-end{-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;}
@mixin flex-justify-start{-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start;}
@mixin flex-flow-row-nowrap{-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-flow:row nowrap;flex-flow:row nowrap;}
@mixin flex-direction-row{-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;}
@mixin flex-direction-column{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;}
@mixin flex-flow-column-wrap{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-flow:column wrap;flex-flow:column wrap;}
@mixin flex-direction-row-reverse{-webkit-box-orient:horizontal;-webkit-box-direction:reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse}
@mixin flex-justify-space-evenly{-webkit-box-pack:space-evenly;-ms-flex-pack:space-evenly;justify-content:space-evenly;}

// Fluid Fonts
$min_width: 350px;
$max_width: 1199px;
$min_font: 13px;
$max_font: 13px;

// Fluid Fonts
@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}